<style lang="scss" scoped>
@import "@styles/_variables";

h2.title{
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 16px;
    margin-bottom: 32px;
}
.notification{
    background: transparent;
    position: absolute;
}
.panel {
	background: $white;
	background: var(--white);
	border-radius: 8px;
	box-shadow: none;

	&-header {
		border-radius: 8px 8px 0 0;
		background: #f2f2f2;
		padding: 10px 20px 0 20px;
	}

	&-content {
		padding: 30px 48px;
	}
}
</style>

<template>
	<div class="columns">
		<b-notification
			:closable="false"
			class="notification"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
		</b-notification>
		<div
			v-if="!loading"
			class="column is-10-tablet is-offset-1-tablet is-8-fullhd is-offset-2-fullhd"
		>
			<h2 class="title is-1">
				Styleguide - {{ activeTab }}
			</h2>

			<div class="panel">
				<div class="panel-header">
					<b-tabs v-model="activeTab">
						<b-tab-item label="Tab 01" />
						<b-tab-item label="Tab 02" />
					</b-tabs>
				</div>
				<div
					v-if="activeTab == 0"
					class="panel-content"
				>
					<div class="columns">
						<div class="column">
							Tab 01 - column 1
						</div>
						<div class="column">
							Tab 01 - column 2
						</div>
					</div>
				</div>
				<div
					v-if="activeTab == 1"
					class="panel-content"
				>
					<div class="columns">
						<div class="column">
							Tab 02 - column 1
						</div>
						<div class="column">
							Tab 02 - column 2
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Styleguide",
	components:{

	},
	data(){
		return {
			loading: true,
			activeTab: 0
		}
	},
	created(){
		this.loading = false;
	}
}
</script>
